.housetype-row {
  height: 150px;
  border-radius: 5px !important;
  box-shadow: none !important;
  cursor: pointer;
  color: rgba(82, 82, 82, 1);
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.housetype-row-selected {
  background-color: #E9600F !important;
  color: white;
  border: none !important;
}

.housetype-content {
  display:grid;
  grid-template-rows: 80% 20%;
}

.housetype-inner-content {
  padding: 5vh;
  display:flex;
  justify-content: center;
  align-items:center;
}

.housingtype-name { 
  text-align: center;
  font-size: 18px;
  color: rgba(255,255,255, 0.8);
}

.housing-type-name-notselected { 
  color: rgba(0, 70, 134, 0.5);
}