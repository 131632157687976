
.customer-option-container {
  height: 50vh;
  border-radius: 20px !important;
  display:flex;
  justify-content: center;
  align-items:center;
  box-shadow: none !important;
  border: 1px solid white;
  color: rgba(82, 82, 82, 0.4);
}

.customer-option-container:hover {
  color: rgba(82, 82, 82, 1);
}

.option-icon {
  display: block; 
  margin: auto;
}

.option-text {
  text-align: center;
  font-size: 2vh;
}
