.search-customer-wrapper { 
    display: grid;
    grid-template-columns: 15% 85%;
    padding-bottom: 2vh;
    padding-right: 2vh;
}

.search-customer-right-inner { 
    padding: 25px;
}

.search-customer-right { 
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.customer-name { 
    font-weight: bold;
}

.search-lead-left {
    background-color: #e9600f;
    border-radius: 10px 0px 0px 10px;
}

.search-lead-left-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-lead-right {
    background-color: #ffffff;
    border-radius: 0px 10px 10px 0px;
}