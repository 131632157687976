.customer-information-inner { 
    padding: 20px;
}

.customer-information-table { 
    margin-top: -20px;
}

.scroll-y {
  overflow-y: scroll !important;
}
