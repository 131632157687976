.calculation-plan-paper { 
    box-shadow: none !important;
    border: none !important;
    min-height: 68vh;
    display: grid;
    grid-template-rows: 15% 40% 25% 20%;
    border-radius: 10px !important;
    padding: 0 !important;
}

.calculation-plan-header { 
    background-color: #E9600F !important;
    display:flex;
    justify-content: center;
    align-items:center;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.calculation-plan-header-inner { 
    color: white;
    font-size: 22px;
    font-weight: bold;
}

.button-refresh { 
    background-color: #004686 !important;
    color: white !important;
  }
  
  .refresh-txt { 
    border-radius: 0 !important;
    background-color: #FAFAFA;
    height: 56px;
    margin-top: 16px !important;
    border: 1px solid #004686 !important;
    text-align: center !important;
  }

.calculation-plan-body { 
    display: grid;
    grid-template-rows: 20% 80%;
}

.subtitle { 
    font-size: 12px;
    color: white;
    font-weight: normal !important;
    text-align: center !important;
}

.calculation-plan-body-title { 
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.abbo-duration { 
    justify-content: center;
    align-items:center;
}

.calculation-plan-resume-body { 
    padding: 20px;
    font-size: 12px;
}

.calculation-plan-price { 
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    padding-top: 15px;
}

.month-duration-change { 
    display:flex;
    justify-content: center;
    align-items:center;
    padding: 0 20px 0 20px;
}

.choose-txt { 
    font-size: 18px;
    color: #E9600F;
}

.negativeBalance {
  color: #dd0000;
}

.tooNegativeBalance {
  font-weight: 800;
  color: #dd0000;
}