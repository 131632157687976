.page-inner-content-lead-calculation {
    padding-left: 5vh;
    padding-right: 5vh;
    max-height: 80vh;
    width: 100%;
    display:flex;
    justify-content: center;
    
    overflow-x: hidden;
    overflow-y: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.page-inner-content-lead-calculation::-webkit-scrollbar {
    display: none;
}