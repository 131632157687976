.resume-container { 
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 1px solid #CCCCCC;
  width: 100%;
  padding: 15px;
  box-shadow: 0 !important;
  text-align: center;
}

.resume-title { 
  text-align: center;
  font-size: 25px;
}

.housing-face-title { 
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 5vh;
}

.implementation-name { 
  text-align: left;
  padding-left: 5vh;
  font-size: 15px;
}

tr.housingFace {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

  tr.housingFace > td {
    font-size: 1em;
    font-weight: 600;
  }

  tr.housingFace > td, tr.housingElement > td, tr.implementation > td {
    border-bottom: 0px;
  }

tr.housingElement {
  background-color: #E1E1E1;
}

tr.housingElement > td {
  font-weight: 600;
}
