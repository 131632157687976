.lastname-input { 
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    font-size: 12px !important;
    margin-left: 5px !important;
    border-left: 1px solid rgba(82, 82, 82, 0.4);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }

  .zipcode-input { 
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    margin-left: 3px !important;
    font-size: 12px !important;
    border-left: 1px solid rgba(82, 82, 82, 0.1);
    border-right: 1px solid rgba(82, 82, 82, 0.1);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }

  .houseNumber-input { 
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    font-size: 12px !important;
    background-color: #FAFAFA;
    height: 55px;
    border-right: 1px solid rgba(82, 82, 82, 0.4);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }
  
.bttn-search { 
    background-color: #E9600F !important;
    color: white !important;
    height: 53px;
    margin-top: 1px !important;
    border-radius: 0 !important;
    width: 100%;
}

.bttn-add { 
  background-color: #E9600F !important;
  color: white !important;
  height: 53px;
  margin-top: 1px !important;
  border-radius: 0 !important;
  border-left: 1px solid rgba(255,255,255, 0.3) !important;
  border-bottom-right-radius: 10px !important;
  margin-left: -5px !important;
  border-top-right-radius: 10px !important;
  width: 100%;
}

.lead-customer-search-content { 
  display: grid;
  width: 100%;
  grid-template-rows: 15% 75%;
}

.lead-container { 
    padding-top: 25px;
    max-height: 60vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

.no-result-message { 
    text-align: center;
    color: white;
    padding-top: 10vh;
}

.customers-container { 
  padding-top: 25px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.no-result-message { 
  text-align: center;
  color: white;
}

.create-customer-paper { 
  padding: 5vh;
}

.create-customer-paper-subtitle { 
  font-weight: bold;
  font-size: 18px;
  padding-top: 10px;
}

.customer-add-grid { 
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
