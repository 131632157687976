.grid-housingFace-content { 
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.typography-heading { 
  flex-basis: 90%;
}

.housing-face-grid { 
  height: 100%;
}

.paper-housingFace { 
  box-shadow: none !important;
  overflow-x: hidden; 
  height: calc(100%);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.selection-face { 
 padding: 20px;
 font-size: 14px;
}

.notselected {
  color: #606060;
}

.housing-element-row {
  padding: 25px;
}

.implementationRow {
  height: 30px;
}

.count {
  text-align: center;
}

