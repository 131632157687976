.page-title
 {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}


.title-wrapper { 
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 10% 90%;
}

.back-buttn {
  font-weight: normal !important;
  font-size: 15px;
  padding-left: 3vh;
  padding-top: 2vh;
  cursor: pointer;
}

.title-container { 
  height: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
}

.title-center { 
  margin-right: 10%; 
}
