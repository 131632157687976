body {
  background-color: #004686;
}

body, html, #app, .page-wrapper {
  width:100%;
  height:100%;
  touch-action: manipulation !important;
  overflow: hidden;
}

.page-wrapper {
  display: grid;
  grid-template-rows: 10% 80% 10%;
}

.page-content {
  display:grid;
  grid-template-rows: 10% 90%;
  overflow: hidden;
}

.page-title { 
  display:-webkit-flex; 
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}

.page-footer { 
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.page-footer-inner { 
  padding: 5vh;
}


.page-inner-content-home {
  padding: 5vh;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}

.page-inner-content-default {
  padding-left: 5vh;
  padding-right: 5vh;
  max-height: 80vh;
  width: 100%;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.page-inner-content-center-message {
  margin: auto;
  margin-top: 100px;
  color: #CCCCCC;
  font-size: 20px;
}

.container-default::-webkit-scrollbar {
  display: none;
}

.paper-default {
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid #CCCCCC;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.app-footer { 
  margin-top: 1vh;
}

.app-button { 
  background-color: white !important;
  color: #E9600F !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 50px !important;
  border: none !important;
  text-transform: capitalize !important;
}

.app-button-cancel { 
  color: red !important;
}


.next-button {
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.next-button-text { 
  font-weight: bold;
  color: white;
  padding-top: 2px;
  cursor: pointer;
}

.recovery {
  height: 50px;
}

#dynamicInput {
  margin-bottom: 10px;
}

#dynamicInput > div {
  width: 100%;
  display: grid;
}

.capitilize-all {
  text-transform: uppercase;
}

.capitilize-first {
  text-transform: capitalize;
}

.loader-overlay {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,70,134,0.1);
  z-index: 3000;
}
.page-title
 {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}


.title-wrapper { 
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 10% 90%;
}

.back-buttn {
  font-weight: normal !important;
  font-size: 15px;
  padding-left: 3vh;
  padding-top: 2vh;
  cursor: pointer;
}

.title-container { 
  height: 100%;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}

.title-center { 
  margin-right: 10%; 
}

.app-header { 
  width: 100%;
  background-color: white;
  height: 100%;
}

.header-content { 
  display: grid;
  height: 100%;
}

.header-inner-content { 
  height: 100%;
  padding-left: 2vh;
  padding-right: 2vh;
}

.menu { 
 padding-left: 5vh;
}

.logo-header { 
  width: 40vh;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}

.user-container { 
  height: 100%;
  margin-left: 4vh;
}

.user-name { 
  margin-left: 1vh;
  font-size: 13px;
}

.home-title
 {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: white;
}

.home-option-container {
  height: 50vh;
  border-radius: 20px !important;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
  box-shadow: none !important;
  border: 1px solid white;
  color: rgba(82, 82, 82, 0.4);
}

.home-option-container:hover {
  color: rgba(82, 82, 82, 1);
}

.option-icon {
  display: block; 
  margin: auto;
}

.option-text {
  text-align: center;
  font-size: 3vh;
}

.logout {
  text-align: center;
  cursor: pointer;
}

.no-org-message {
  padding: 1rem;
  color: #606060;
}

.login-wrapper { 
  display: grid;
  height: 100%;
  grid-template-rows: 100%;
}

.login-content { 
  display:grid;
  grid-template: 100%;
}

.login-inner-content {
  padding: 5vh;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}


.login-form-placeholer { 
  padding-bottom: 5px;
  font-size: 12px;
  color: #525252;
}

.login-form-input { 
  border-radius: 0px !important;
  background-color: #FAFAFA;
  border: 1px solid rgba(82, 82, 82, 0.4);
}

.login-title { 
  font-size: 30px;
  padding-top: 5vh;
  color: #525252;
}

.login-container {
  padding-top: 20vh;
}
.login-content {
  padding: 50px;
}


.login-error-message {
  color: red;
  text-align: center;
}

.option-text {
  padding-top: 5vh;

}

.password-form-group {  
  padding-top: 10px;
}

.logo-login { 
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-bttn { 
  background-color: #004686 !important;
  border-radius: 0 !important;
}
.housetype-row {
  height: 150px;
  border-radius: 5px !important;
  box-shadow: none !important;
  cursor: pointer;
  color: rgba(82, 82, 82, 1);
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.housetype-row-selected {
  background-color: #E9600F !important;
  color: white;
  border: none !important;
}

.housetype-content {
  display:grid;
  grid-template-rows: 80% 20%;
}

.housetype-inner-content {
  padding: 5vh;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
}

.housingtype-name { 
  text-align: center;
  font-size: 18px;
  color: rgba(255,255,255, 0.8);
}

.housing-type-name-notselected { 
  color: rgba(0, 70, 134, 0.5);
}
.implementationBttn {
  text-align: center;
  font-size: 25px;
} 

.count {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.button-plus,
.button-min { 
  background-color: #E9600F !important;
  color: white !important;
}

.button-plus-disabled,
.button-min-disabled {
  background-color: #5e5e5e !important;
  color: white !important;
}
.housing-element-row {
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
  border-radius: 5px;
  width: 100%;
}

.implementation-price { 
  font-size: 12px;
  color: #E9600F;
}

.housing-element-message { 
  text-align: center;
  font-size: 13px;
}
.modal-wrapper {
    display: grid;
    grid-template-rows: 15% 70% 15%;
    height: 100%;
    width: 100%;
  }

.modal-header { 
    background-color: rgba(0, 70, 134, 1.0);
    border: 1px solid rgba(0, 70, 134, 1.0);
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.modal-content { 
    border-left: 1px solid rgba(0, 70, 134, 0.5);
    border-right: 1px solid rgba(0, 70, 134, 0.5);
    border-radius: 0 !important;
    border-bottom: 0 !important;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.modal-footer { 
    display:-webkit-flex; 
    display:flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-left: 1px solid rgba(0, 70, 134, 0.5);
    border-right: 1px solid rgba(0, 70, 134, 0.5);
    border-bottom: 1px solid rgba(0, 70, 134, 0.5);
    border-top: 0 !important;
}

.mondal-title { 
    color: rgba(255,255,255, 0.6);
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.modal-inner-content { 
    padding: 25px;
}

.element-grid { 
    padding-top: 10px;
    padding-bottom: 10px;
}

.housing-element-name { 
    font-weight: bold !important;
}
.resume-container { 
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 1px solid #CCCCCC;
  width: 100%;
  padding: 15px;
  box-shadow: 0 !important;
  text-align: center;
}

.resume-title { 
  text-align: center;
  font-size: 25px;
}

.housing-face-title { 
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 5vh;
}

.implementation-name { 
  text-align: left;
  padding-left: 5vh;
  font-size: 15px;
}

tr.housingFace {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

  tr.housingFace > td {
    font-size: 1em;
    font-weight: 600;
  }

  tr.housingFace > td, tr.housingElement > td, tr.implementation > td {
    border-bottom: 0px;
  }

tr.housingElement {
  background-color: #E1E1E1;
}

tr.housingElement > td {
  font-weight: 600;
}

.questionBox .modal-content {
    overflow: hidden;
}
  
.questionBox .modal-title {
    color: rgba(255,255,255, 0.6);
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
  
.questionBox button {
    border-radius: 5px;
    background-color: #E9600F;
    color: white;
    -webkit-flex-grow: 1;
            flex-grow: 1;
}
.grid-housingFace-content { 
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.typography-heading { 
  -webkit-flex-basis: 90%; 
          flex-basis: 90%;
}

.housing-face-grid { 
  height: 100%;
}

.paper-housingFace { 
  box-shadow: none !important;
  overflow-x: hidden; 
  height: calc(100%);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.selection-face { 
 padding: 20px;
 font-size: 14px;
}

.notselected {
  color: #606060;
}

.housing-element-row {
  padding: 25px;
}

.implementationRow {
  height: 30px;
}

.count {
  text-align: center;
}


.calculation-plan-paper { 
    box-shadow: none !important;
    border: none !important;
    min-height: 68vh;
    display: grid;
    grid-template-rows: 15% 40% 25% 20%;
    border-radius: 10px !important;
    padding: 0 !important;
}

.calculation-plan-header { 
    background-color: #E9600F !important;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.calculation-plan-header-inner { 
    color: white;
    font-size: 22px;
    font-weight: bold;
}

.button-refresh { 
    background-color: #004686 !important;
    color: white !important;
  }
  
  .refresh-txt { 
    border-radius: 0 !important;
    background-color: #FAFAFA;
    height: 56px;
    margin-top: 16px !important;
    border: 1px solid #004686 !important;
    text-align: center !important;
  }

.calculation-plan-body { 
    display: grid;
    grid-template-rows: 20% 80%;
}

.subtitle { 
    font-size: 12px;
    color: white;
    font-weight: normal !important;
    text-align: center !important;
}

.calculation-plan-body-title { 
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.abbo-duration { 
    -webkit-justify-content: center; 
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
}

.calculation-plan-resume-body { 
    padding: 20px;
    font-size: 12px;
}

.calculation-plan-price { 
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    padding-top: 15px;
}

.month-duration-change { 
    display:-webkit-flex; 
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
    padding: 0 20px 0 20px;
}

.choose-txt { 
    font-size: 18px;
    color: #E9600F;
}

.negativeBalance {
  color: #dd0000;
}

.tooNegativeBalance {
  font-weight: 800;
  color: #dd0000;
}
.page-inner-content-lead-calculation {
    padding-left: 5vh;
    padding-right: 5vh;
    max-height: 80vh;
    width: 100%;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    
    overflow-x: hidden;
    overflow-y: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.page-inner-content-lead-calculation::-webkit-scrollbar {
    display: none;
}
.customer-information-inner { 
    padding: 20px;
    max-height: 53vh;
    overflow: hidden;
    overflow-y: scroll;
}

.customer-information-table { 
    margin-top: -20px;
    margin-right: 20px;
}

.customer-information-title { 
    font-weight: bold !important;
}
.customer-information-inner { 
    padding: 20px;
}

.customer-information-table { 
    margin-top: -20px;
}

.scroll-y {
  overflow-y: scroll !important;
}

.information-title { 
    font-size: 18px;
    font-weight: bold;
}

.customer-information-inner {
  overflow-y: hidden;
}

.paper-default {
  overflow: hidden;
}

.invalid-message {
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  background-color: #E9600F !important;
  border: 0px;
  padding-top: 1rem;
}

.overview-component { 
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.sigCanvas { 
    border: 1px solid black !important;
    width: 100% !important;
    height: 250px !important;
}

.bttn-save {
  background-color: #E9600F !important;
  color: white !important;
  height: 53px;
  border-radius: 10px !important;
  width: 100%;
}

.search-customer-wrapper { 
    display: grid;
    grid-template-columns: 15% 85%;
    padding-bottom: 2vh;
    padding-right: 2vh;
}

.search-customer-right-inner { 
    padding: 25px;
}

.search-customer-right { 
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.customer-name { 
    font-weight: bold;
}

.search-lead-left {
    background-color: #e9600f;
    border-radius: 10px 0px 0px 10px;
}

.search-lead-left-inner {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.search-lead-right {
    background-color: #ffffff;
    border-radius: 0px 10px 10px 0px;
}
.lastname-input { 
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    font-size: 12px !important;
    margin-left: 5px !important;
    border-left: 1px solid rgba(82, 82, 82, 0.4);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }

  .zipcode-input { 
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    margin-left: 3px !important;
    font-size: 12px !important;
    border-left: 1px solid rgba(82, 82, 82, 0.1);
    border-right: 1px solid rgba(82, 82, 82, 0.1);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }

  .houseNumber-input { 
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    font-size: 12px !important;
    background-color: #FAFAFA;
    height: 55px;
    border-right: 1px solid rgba(82, 82, 82, 0.4);
    border-top: 1px solid rgba(82, 82, 82, 0.4);
    border-bottom: 1px solid rgba(82, 82, 82, 0.4);
  }
  
.bttn-search { 
    background-color: #E9600F !important;
    color: white !important;
    height: 53px;
    margin-top: 1px !important;
    border-radius: 0 !important;
    width: 100%;
}

.bttn-add { 
  background-color: #E9600F !important;
  color: white !important;
  height: 53px;
  margin-top: 1px !important;
  border-radius: 0 !important;
  border-left: 1px solid rgba(255,255,255, 0.3) !important;
  border-bottom-right-radius: 10px !important;
  margin-left: -5px !important;
  border-top-right-radius: 10px !important;
  width: 100%;
}

.lead-customer-search-content { 
  display: grid;
  width: 100%;
  grid-template-rows: 15% 75%;
}

.lead-container { 
    padding-top: 25px;
    max-height: 60vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

.no-result-message { 
    text-align: center;
    color: white;
    padding-top: 10vh;
}

.customers-container { 
  padding-top: 25px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.no-result-message { 
  text-align: center;
  color: white;
}

.create-customer-paper { 
  padding: 5vh;
}

.create-customer-paper-subtitle { 
  font-weight: bold;
  font-size: 18px;
  padding-top: 10px;
}

.customer-add-grid { 
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.finish-content {
  width: 50vw;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.finish-icon { 
    display:-webkit-flex; 
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items:center;
            align-items:center;
}

.finish-message { 
    color: white;
}

.email-placeholder { 
    color: white;
}

.page-inner-content-default {
    padding: 30px;
}

.bttn-mail { 
    background-color: #E9600F !important;
    color: white !important;
    height: 53px;
    margin-top: 1px !important;
    border-radius: 10px !important;
    width: 100%;
}

.year-cell { 
    color: #E9600F !important; 
    font-weight: bold !important;
    font-size: 16px !important;
    width: 100px;
}

.total-price-cell {
  white-space: nowrap;
  width: 10%;
}

.correction-cell {
  min-width: 360px;
}

.total-price { 
    font-weight: bold !important;
    font-size: 18px !important;
    width: 100px !important;
    white-space: nowrap;
}

.housing-plan-add-bttn {
    margin-top: 16px !important;
    font-size: 1rem !important;
    white-space: nowrap;
    height: 56px;
}

  .abbo-duration-button { 
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    font-size: 15px !important;
    padding: 15px !important;
  }
  
  .abbo-duration-txt { 
    border-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    margin-top: 16px !important;
    border: 1px solid #E9600F !important;
    text-align: center !important;
  }

  .abbo-durage-message { 
      text-align: center;
      font-weight: bold;
      margin-top: 4vh;
      
  }

  .title-abbo-durationchange { 
    font-weight: bold;
    font-size: 15px;
    padding-top: 10vh;
  }

.abbo-startyear-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 15px !important;
  padding: 15px !important;
}

.abbo-startyear-txt {
  border-radius: 0 !important;
  background-color: #FAFAFA;
  height: 55px;
  margin-top: 16px !important;
  border: 1px solid #E9600F !important;
  text-align: center !important;
}

.abbo-startyear-message {
  text-align: center;
  font-weight: bold;
  margin-top: 4vh;
}

.title-abbo-startyearchange {
  font-weight: bold;
  font-size: 15px;
  padding-top: 10vh;
}

.tablehousingPlan { 
    font-size: 1px; 
}

.housing-plan-headInformation { 
    padding: 25px;
    box-shadow: none !important;
    border-radius: 5px !important;
}

.housingplan-panel { 
    border-radius: 5px !important;
    margin-top: 5px;
}

.housingplan-inner { 
    height: 51vh;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 100px;
}

.previous-button { 
    margin-right: 10vh;
}

.housingface-element-innertable { 
    overflow: hidden;
    overflow-y: visible;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.houseplan-edit-rowheight { 
    height: 70px !important;
}

.selected-Cell {
    background-color: rgba(233, 96, 15, 0.5) !important;
}

.edithousingplan-table { 
    text-align: center !important;
}

.all-faces { 
    font-weight: bold !important;
    color: rgba(233, 96, 15, 1) !important;
}


.customer-option-container {
  height: 50vh;
  border-radius: 20px !important;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
  box-shadow: none !important;
  border: 1px solid white;
  color: rgba(82, 82, 82, 0.4);
}

.customer-option-container:hover {
  color: rgba(82, 82, 82, 1);
}

.option-icon {
  display: block; 
  margin: auto;
}

.option-text {
  text-align: center;
  font-size: 2vh;
}


.photo-paper {
  width: 100%;
  display: grid;
  border-radius: 10px !important;
}

  .photo-paper:not(:last-child) {
    margin-bottom: 1rem;
  }

.photo-header {
  background-color: #E9600F !important;
  padding: 0.2rem;
  max-height: 2.4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
}

.photo-grid {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.photo-item {
  -webkit-order: -1;
          order: -1;  
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
}

  .photo-item:first-child {
    border-bottom-left-radius: 10px;
  }

.add-button {
  background-color: #E0E0E0;
}

.photo-image-container {
  height: 120px;
}
.photo-image {
  max-height: 100%;
}

.photo-name {
  font-size: small;
}

.photo-description {
  width: 100%;
}

.photo-delete-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 2.4em;
  height: 2.4em;
  margin: 0.4em;
  background-image: url(/static/media/trash.17df4799.svg);
  background-color: #E0E0E0;
  border: 0.4em solid #E0E0E0;
  border-radius: 0.2em;
}

.confirm-container {
  width: 100%;
  overflow-y: scroll;
}

.confirm-paper {
  padding: 3em;
  overflow-y: auto;
}

.hidden {
  display: none;
}

.moisturePercentage {
  width: 12em;
}

.repair-input-container {
  position: relative;
  margin-bottom: 0.4em;
}

.repair-input {
  width: calc(100% - 1.8em);
}

.add-repair-button {
  background-color: #E9600F !important;
}

.delete-repair-button {
  position: absolute !important;
  top: 0px;
  right: 0px;
  width: 1.8em;
  height: 1.8em;
  min-width: 1.8em !important;
  margin: 0.4em;
  background-image: url(/static/media/trash.17df4799.svg);
  background-color: #E0E0E0;
  border: 0.4em solid #E0E0E0;
  border-radius: 0.2em;
}

.photo-container {
  width: 100%;
  overflow-y: auto;
}

.page-inner-content-version p {
    color: #ffffff;
    font-size: 20px;
}
.page-inner-content-idle-timer p {
    color: #ffffff;
    font-size: 20px;
}
