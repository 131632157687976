.app-header { 
  width: 100%;
  background-color: white;
  height: 100%;
}

.header-content { 
  display: grid;
  height: 100%;
}

.header-inner-content { 
  height: 100%;
  padding-left: 2vh;
  padding-right: 2vh;
}

.menu { 
 padding-left: 5vh;
}

.logo-header { 
  width: 40vh;
  display:flex;
  justify-content: center;
  align-items:center;
}

.user-container { 
  height: 100%;
  margin-left: 4vh;
}

.user-name { 
  margin-left: 1vh;
  font-size: 13px;
}
