body {
  background-color: #004686;
}

body, html, #app, .page-wrapper {
  width:100%;
  height:100%;
  -ms-touch-action: manipulation !important;
  touch-action: manipulation !important;
  overflow: hidden;
}

.page-wrapper {
  display: grid;
  grid-template-rows: 10% 80% 10%;
}

.page-content {
  display:grid;
  grid-template-rows: 10% 90%;
  overflow: hidden;
}

.page-title { 
  display:flex;
  justify-content: center;
  align-items:center;
}

.page-footer { 
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-footer-inner { 
  padding: 5vh;
}


.page-inner-content-home {
  padding: 5vh;
  display:flex;
  justify-content: center;
  align-items:center;
}

.page-inner-content-default {
  padding-left: 5vh;
  padding-right: 5vh;
  max-height: 80vh;
  width: 100%;
  display:flex;
  justify-content: center;
}

.page-inner-content-center-message {
  margin: auto;
  margin-top: 100px;
  color: #CCCCCC;
  font-size: 20px;
}

.container-default::-webkit-scrollbar {
  display: none;
}

.paper-default {
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid #CCCCCC;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.app-footer { 
  margin-top: 1vh;
}

.app-button { 
  background-color: white !important;
  color: #E9600F !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 50px !important;
  border: none !important;
  text-transform: capitalize !important;
}

.app-button-cancel { 
  color: red !important;
}


.next-button {
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}

.next-button-text { 
  font-weight: bold;
  color: white;
  padding-top: 2px;
  cursor: pointer;
}

.recovery {
  height: 50px;
}

#dynamicInput {
  margin-bottom: 10px;
}

#dynamicInput > div {
  width: 100%;
  display: grid;
}

.capitilize-all {
  text-transform: uppercase;
}

.capitilize-first {
  text-transform: capitalize;
}

.loader-overlay {
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,70,134,0.1);
  z-index: 3000;
}