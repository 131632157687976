.login-wrapper { 
  display: grid;
  height: 100%;
  grid-template-rows: 100%;
}

.login-content { 
  display:grid;
  grid-template: 100%;
}

.login-inner-content {
  padding: 5vh;
  display:flex;
  justify-content: center;
  align-items:center;
}


.login-form-placeholer { 
  padding-bottom: 5px;
  font-size: 12px;
  color: #525252;
}

.login-form-input { 
  border-radius: 0px !important;
  background-color: #FAFAFA;
  border: 1px solid rgba(82, 82, 82, 0.4);
}

.login-title { 
  font-size: 30px;
  padding-top: 5vh;
  color: #525252;
}

.login-container {
  padding-top: 20vh;
}
.login-content {
  padding: 50px;
}


.login-error-message {
  color: red;
  text-align: center;
}

.option-text {
  padding-top: 5vh;

}

.password-form-group {  
  padding-top: 10px;
}

.logo-login { 
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-bttn { 
  background-color: #004686 !important;
  border-radius: 0 !important;
}