.housing-element-row {
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
  border-radius: 5px;
  width: 100%;
}

.implementation-price { 
  font-size: 12px;
  color: #E9600F;
}

.housing-element-message { 
  text-align: center;
  font-size: 13px;
}