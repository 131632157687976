.questionBox .modal-content {
    overflow: hidden;
}
  
.questionBox .modal-title {
    color: rgba(255,255,255, 0.6);
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
  
.questionBox button {
    border-radius: 5px;
    background-color: #E9600F;
    color: white;
    flex-grow: 1;
}