.implementationBttn {
  text-align: center;
  font-size: 25px;
} 

.count {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.button-plus,
.button-min { 
  background-color: #E9600F !important;
  color: white !important;
}

.button-plus-disabled,
.button-min-disabled {
  background-color: #5e5e5e !important;
  color: white !important;
}