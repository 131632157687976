.confirm-container {
  width: 100%;
  overflow-y: scroll;
}

.confirm-paper {
  padding: 3em;
  overflow-y: auto;
}

.hidden {
  display: none;
}

.moisturePercentage {
  width: 12em;
}

.repair-input-container {
  position: relative;
  margin-bottom: 0.4em;
}

.repair-input {
  width: calc(100% - 1.8em);
}

.add-repair-button {
  background-color: #E9600F !important;
}

.delete-repair-button {
  position: absolute !important;
  top: 0px;
  right: 0px;
  width: 1.8em;
  height: 1.8em;
  min-width: 1.8em !important;
  margin: 0.4em;
  background-image: url('../../../images/trash.svg');
  background-color: #E0E0E0;
  border: 0.4em solid #E0E0E0;
  border-radius: 0.2em;
}
