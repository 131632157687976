.information-title { 
    font-size: 18px;
    font-weight: bold;
}

.customer-information-inner {
  overflow-y: hidden;
}

.paper-default {
  overflow: hidden;
}

.invalid-message {
  overflow: hidden;
  font-weight: 600;
  text-align: center;
  background-color: #E9600F !important;
  border: 0px;
  padding-top: 1rem;
}

.overview-component { 
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.sigCanvas { 
    border: 1px solid black !important;
    width: 100% !important;
    height: 250px !important;
}

.bttn-save {
  background-color: #E9600F !important;
  color: white !important;
  height: 53px;
  border-radius: 10px !important;
  width: 100%;
}
