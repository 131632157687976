.finish-content {
  width: 50vw;
  height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.finish-icon { 
    display:flex;
    justify-content: center;
    align-items:center;
}

.finish-message { 
    color: white;
}

.email-placeholder { 
    color: white;
}

.page-inner-content-default {
    padding: 30px;
}

.bttn-mail { 
    background-color: #E9600F !important;
    color: white !important;
    height: 53px;
    margin-top: 1px !important;
    border-radius: 10px !important;
    width: 100%;
}
