.modal-wrapper {
    display: grid;
    grid-template-rows: 15% 70% 15%;
    height: 100%;
    width: 100%;
  }

.modal-header { 
    background-color: rgba(0, 70, 134, 1.0);
    border: 1px solid rgba(0, 70, 134, 1.0);
    display:flex;
    justify-content: center;
    align-items:center;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.modal-content { 
    border-left: 1px solid rgba(0, 70, 134, 0.5);
    border-right: 1px solid rgba(0, 70, 134, 0.5);
    border-radius: 0 !important;
    border-bottom: 0 !important;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.modal-footer { 
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-left: 1px solid rgba(0, 70, 134, 0.5);
    border-right: 1px solid rgba(0, 70, 134, 0.5);
    border-bottom: 1px solid rgba(0, 70, 134, 0.5);
    border-top: 0 !important;
}

.mondal-title { 
    color: rgba(255,255,255, 0.6);
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.modal-inner-content { 
    padding: 25px;
}

.element-grid { 
    padding-top: 10px;
    padding-bottom: 10px;
}

.housing-element-name { 
    font-weight: bold !important;
}