.customer-information-inner { 
    padding: 20px;
    max-height: 53vh;
    overflow: hidden;
    overflow-y: scroll;
}

.customer-information-table { 
    margin-top: -20px;
    margin-right: 20px;
}

.customer-information-title { 
    font-weight: bold !important;
}