.photo-paper {
  width: 100%;
  display: grid;
  border-radius: 10px !important;
}

  .photo-paper:not(:last-child) {
    margin-bottom: 1rem;
  }

.photo-header {
  background-color: #E9600F !important;
  padding: 0.2rem;
  max-height: 2.4rem;
  display: flex;
  justify-content: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
}

.photo-grid {
  align-items: stretch;
}

.photo-item {
  order: -1;  
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

  .photo-item:first-child {
    border-bottom-left-radius: 10px;
  }

.add-button {
  background-color: #E0E0E0;
}

.photo-image-container {
  height: 120px;
}
.photo-image {
  max-height: 100%;
}

.photo-name {
  font-size: small;
}

.photo-description {
  width: 100%;
}

.photo-delete-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 2.4em;
  height: 2.4em;
  margin: 0.4em;
  background-image: url('../../../../images/trash.svg');
  background-color: #E0E0E0;
  border: 0.4em solid #E0E0E0;
  border-radius: 0.2em;
}
