.tablehousingPlan { 
    font-size: 1px; 
}

.housing-plan-headInformation { 
    padding: 25px;
    box-shadow: none !important;
    border-radius: 5px !important;
}

.housingplan-panel { 
    border-radius: 5px !important;
    margin-top: 5px;
}

.housingplan-inner { 
    height: 51vh;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 100px;
}

.previous-button { 
    margin-right: 10vh;
}

.housingface-element-innertable { 
    overflow: hidden;
    overflow-y: visible;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
}

.houseplan-edit-rowheight { 
    height: 70px !important;
}

.selected-Cell {
    background-color: rgba(233, 96, 15, 0.5) !important;
}

.edithousingplan-table { 
    text-align: center !important;
}

.all-faces { 
    font-weight: bold !important;
    color: rgba(233, 96, 15, 1) !important;
}
