.year-cell { 
    color: #E9600F !important; 
    font-weight: bold !important;
    font-size: 16px !important;
    width: 100px;
}

.total-price-cell {
  white-space: nowrap;
  width: 10%;
}

.correction-cell {
  min-width: 360px;
}

.total-price { 
    font-weight: bold !important;
    font-size: 18px !important;
    width: 100px !important;
    white-space: nowrap;
}

.housing-plan-add-bttn {
    margin-top: 16px !important;
    font-size: 1rem !important;
    white-space: nowrap;
    height: 56px;
}

  .abbo-duration-button { 
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    font-size: 15px !important;
    padding: 15px !important;
  }
  
  .abbo-duration-txt { 
    border-radius: 0 !important;
    background-color: #FAFAFA;
    height: 55px;
    margin-top: 16px !important;
    border: 1px solid #E9600F !important;
    text-align: center !important;
  }

  .abbo-durage-message { 
      text-align: center;
      font-weight: bold;
      margin-top: 4vh;
      
  }

  .title-abbo-durationchange { 
    font-weight: bold;
    font-size: 15px;
    padding-top: 10vh;
  }

.abbo-startyear-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  font-size: 15px !important;
  padding: 15px !important;
}

.abbo-startyear-txt {
  border-radius: 0 !important;
  background-color: #FAFAFA;
  height: 55px;
  margin-top: 16px !important;
  border: 1px solid #E9600F !important;
  text-align: center !important;
}

.abbo-startyear-message {
  text-align: center;
  font-weight: bold;
  margin-top: 4vh;
}

.title-abbo-startyearchange {
  font-weight: bold;
  font-size: 15px;
  padding-top: 10vh;
}
